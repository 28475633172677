import Vue from "vue";
import App from "./App.vue";
import router from "./router";
window.$ = window.jQuery = require('jquery');
import 'jquery';
window.tata = require('tata-js');
import 'tata-js';
import VueHtmlToPaper from 'vue-html-to-paper';

import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue2-datepicker/index.css';

import partnersModule from "./modules/partners";
import finderModule from "./modules/finder";
import loansModule from "./modules/loans";
import emailsModule from "./modules/emails";

import { registerModules } from "./register-modules";
import vuetify from "./plugins/vuetify";

import VuetifyConfirm from 'vuetify-confirm';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC-9i5fcufY3_hHYPcW7pNjwqyr293x_D8",
  authDomain: "winter-jet-344803.firebaseapp.com",
  projectId: "winter-jet-344803",
  storageBucket: "winter-jet-344803.appspot.com",
  messagingSenderId: "679856677566",
  appId: "1:679856677566:web:bed7b479336c62d23574ef"
};

initializeApp(firebaseConfig);

registerModules({
  partners: partnersModule,
  loans: loansModule,
  finder: finderModule,
  emails: emailsModule
});

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=no',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.config.productionTip = false;
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(VueHtmlToPaper, options);
Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
  });
  return formatter.format(value);
});

export const eventBus = new Vue();

let app = '';

getAuth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }
});



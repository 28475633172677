import { http } from '../../../config'
import moment from 'moment';

function montarUrlComFiltros(
    partnerName, 
    contractStatus, 
    initDate, 
    finalDate
) {
    var defaultUrl = `contratos/buscaAvancada?`;

    if (partnerName && partnerName !== 'All') {
        defaultUrl += `partner=${partnerName}&`
    }

    if (contractStatus && contractStatus !== 'All') {
        defaultUrl += `contractStatus=${contractStatus}&`
    }

    if (initDate && finalDate) {
        defaultUrl += `initDate=${initDate}&finalDate=${finalDate}`
    }

    return defaultUrl;
}

function encodeRFC5987ValueChars (str) {
    return encodeURIComponent(str).
        // Note that although RFC3986 reserves "!", RFC5987 does not,
        // so we do not need to escape it
        replace(/['()]/g, escape). // i.e., %27 %28 %29
        replace(/\*/g, '%2A').
            // The following are not required for percent-encoding per RFC5987,
            // so we can allow for a little better readability over the wire: |`^
            replace(/%(?:7C|60|5E)/g, unescape);
}

export default {
    findAll: (page, size) => {
        return http.get(`contratos?page=${page}&size=${size}`)
    },

    findById: (loanId) => {
        return http.get(`contratos/${loanId}`)
    },

    findByIdReport: (loanId) => {
        return http.get(`contratos/${loanId}/report`)
    },

    create: (loan) => {
        return http.post(`contratos`, loan)
    },

    update: (loan) => {
        return http.put(`contratos/${loan.id}`, loan)
    },

    inactive: (idLoan) => {
        return http.patch(`contratos/${idLoan}/inativar`);
    },

    pay: (idsContratos, listParcelas, payDate, isPayoff, comentario, valorTotalPayOff) => {
        return http.post(`contratos/pagar?idsContratos=${idsContratos}&nrosParcela=${listParcelas}&dataPagamento=${payDate ? payDate : moment().format("YYYY-MM-DD")}&ispayoff=${isPayoff ? isPayoff : 'false'}&comentario=${comentario ? encodeRFC5987ValueChars(comentario) : ''}&valorPayoff=${valorTotalPayOff}`)
    },

    searchByStock: (stock) => {
        return http.get(`contratos/stock/${stock}`);
    },

    calculatePayoff: (contratoId, data) => {
        return http.get(`contratos/${contratoId}/calculatePayoff?data=${data}`)
    },

    buscaAvancada: (
        partnerName, 
        paymentStatus, 
        initDate, 
        finalDate) => {
        return http.get(montarUrlComFiltros(partnerName, paymentStatus, initDate, finalDate))
    }
    
}
<template>
  <v-container>
    <v-row justify="center" align="center" style="margin-top: 15px; margin-bottom: 15px;">
      <v-icon v-on:click="$router.back()" style="margin-right: 40px;">
        mdi-arrow-left
      </v-icon>

      <h1>
        Emitted Reports
      </h1>
    </v-row>

    <v-container class="text-center">
      <v-row align="center" justify="center" cols="">
        <v-col cols="12" sm="6" md="3" lg="3">
          <span>Start date</span> <br>
          <date-picker v-model="finder.initDate" valueType="format" style="width: inherit;"></date-picker>
        </v-col>

        <v-col cols="12" sm="6" md="3" lg="3">
          <span>End date</span> <br>
          <date-picker v-model="finder.finalDate" valueType="format" style="width: inherit;"></date-picker>
        </v-col>

        <v-col cols="12" sm="12" md="3" lg="3">
          <br>
          <v-text-field 
            v-model="observation" 
            label="Observation"
            hide-details="auto"
            outlined
            dense>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="1" lg="1">
          <v-btn style="margin-top: 22px; margin-right: 12px;" depressed color="primary" v-on:click="find()">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col> 
      </v-row>
    </v-container>

    <v-row v-if="isLoading" justify="center" no-gutters class="my-12">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-row>

    <v-row v-if="isFirstLoading && !isLoading" justify="center" no-gutters class="my-12">
      <v-alert dense type="info">
        Please, use filters to find emitted reports.
      </v-alert>
    </v-row>

    <v-row v-if="!reports.length && !isLoading && !isFirstLoading" justify="center" no-gutters class="my-12">
      <v-alert dense type="info">
        Not found any reports with the select filters.
      </v-alert>
    </v-row>

    <v-container v-if="reports.length">
      <v-row justify="center" class="table table-bordered" style="margin-top: 10px;">
        <v-simple-table
          dense
          style="width: 100%;"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th scope="col" class="text-center">ID</th>
                <th scope="col" class="text-center" style="min-width: 200px;">CREATED AT</th>
                <th scope="col" class="text-center">TYPE</th>
                <th scope="col" class="text-center">OBSERVATION</th>
                <th scope="col" class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="report in reports"
                :key="report.id"
              >
                <td class="text-center">{{ report.id }}</td>
                <td class="text-center">{{ formatDate(report.created_at) }}</td>
                <td class="text-center">{{ report.type === 'P' ? 'Loans' : 'Contracts' }}</td>
                <td class="text-center">{{ report.observation }}</td>
                <td>
                  <v-btn :disabled="!report.presignedUrl" v-on:click="getDocument(report)" color="primary" height="23" max-width="20" min-width="20" depressed>
                    <v-icon v-if="report.path" >mdi-cloud-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </v-container>
  </v-container>
    
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

import ReportService from '../modules/finder/services/ReportService';

export default {
  name: 'EmittedReports',
  components: {
    DatePicker
  },

  data() {
    return {
      finder: {
        initDate: null,
        finalDate: null
      },
      reports: [],
      observation: null,
      isLoading: false,
      isFirstLoading: true
    }
  },

  methods: {
    find() {
      const filtros = {
        initDate: this.finder.initDate,
        finalDate: this.finder.finalDate,
        observation: this.observation
      }

      this.reports = [];
      this.isLoading = true;

      ReportService.findReports(filtros).then(response => {
        this.reports = response.data;
      }).finally(() => {
        this.isLoading = false;
        this.isFirstLoading = false;
      })
    },

    getDocument(report) {
      window.open(report.presignedUrl, '_blank').focus();
    },

    formatDate(data) {
      return moment(data).format('YYYY-MM-DD hh:mm')
    }
  }
}
</script>

<template>
  <div :key="componentKey">
    <v-row justify="center" style="margin-top: 15px;">
      <h1 class="mb-3 text-center">Contracts</h1>
    </v-row>

    <v-container class="text-center" id="test">
      <v-row align="center" justify="center" cols="">
        <v-col cols="12" sm="6" md="2" lg="2">
          <span>Partner</span>
          <model-list-select :list="partners"
                      v-model="finder.partner"
                      option-value="nome"
                      option-text="nome"
                      placeholder="select item"
                      style="height: 35px;">
          </model-list-select>
        </v-col>
        
        <v-col cols="12" sm="6" md="2" lg="2">
          <span>Situation</span>
          <model-list-select :list="options"
                      v-model="finder.situation"
                      option-value="value"
                      option-text="value"
                      placeholder="select item"
                      style="height: 35px;">
          </model-list-select>
        </v-col>

        <v-col cols="12" sm="6" md="2" lg="2">
          <span>Start date</span> <br>
          <date-picker v-model="finder.startDate" valueType="format" style="width: inherit;"></date-picker>
        </v-col>

        <v-col cols="12" sm="6" md="2" lg="2">
          <span>End date</span> <br>
          <date-picker v-model="finder.endDate" valueType="format" style="width: inherit;"></date-picker>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-btn style="margin-top: 22px; margin-right: 12px;" depressed color="primary" v-on:click="executeSearch(finder)">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>

          <v-btn title="New report" style="margin-top: 22px; margin-right: 12px;" v-on:click="createReportProps.dialogOpened = true" v-if="contracts.length" depressed>
              +
              <v-icon>
                  mdi-file-document
              </v-icon>
          </v-btn>

          <v-btn style="margin-top: 22px;" v-on:click="$router.push('/emitted-reports')" title="See emitted reports" depressed>
              <v-icon>
                  mdi-file-check
              </v-icon>
          </v-btn>
        </v-col>  
      </v-row>
    </v-container>
    
    <v-row v-if="isLoading" justify="center" no-gutters class="my-12">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-row>

    <v-row v-if="!contracts.length && !isLoading" justify="center" no-gutters class="my-12">
      <v-alert dense type="info">
        Not found any contracts with the select filters.
      </v-alert>
    </v-row>

    <v-container id="printer-wrapper">

      <v-row v-if="showFilters" justify="center" style="margin-top: 20px;">
        <div class="text-center" style="display: flex; justify-content: space-around;">
          <v-col cols="12" sm="8" md="2" lg="2">
            <span><h4>Partner: {{ finder.partner }}</h4></span> <br>
          </v-col>
          <v-col cols="12" sm="8" md="2" lg="2">
            <span><h4>Situation: {{ finder.situation }}</h4></span> <br>
          </v-col>
          <v-col cols="12" sm="8" md="2" lg="2">
            <span><h4>Start date: {{ finder.startDate }}</h4></span> <br>
          </v-col>
          <v-col cols="12" sm="8" md="2" lg="2">
            <span><h4>End date: {{ finder.endDate }}</h4></span> <br>
          </v-col>
        </div>
      </v-row>

      <v-row justify="center" class="table table-bordered" style="margin-top: 10px;">
        <v-simple-table
          dense
          v-if="!isLoading && contracts.length"
          style="width: 100%;"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th scope="col" class="text-center">Partner</th>
                <th scope="col" class="text-center">Stock</th>
                <th scope="col" class="text-center" style="min-width: 120px;">Date</th>
                <th scope="col" class="text-center">Payment</th>
                <th scope="col" class="text-center">Total payment</th>
                <th scope="col" class="text-center" style="min-width: 100px;">Status</th>
                <th scope="col" class="text-center" style="min-width: 120px;">Paid date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="contrato in contracts"
                :key="contrato.id"
              >
                <td class="text-center"><a v-on:click="loadPartner(contrato.banco.id)">{{ contrato.banco.nome }}</a></td>
                <td class="text-center"><a v-on:click="loadLoan(contrato.id)">{{ contrato.stock }}</a></td>
                <td class="text-center">{{ contrato.dataInicioContrato }}</td>
                <td class="text-center">{{ contrato.valorParcela | toCurrency }}</td>
                <td class="text-center">{{ contrato.totalPagar | toCurrency }}</td>
                <td class="text-center"><v-btn x-small :color="defineColor(contrato)">{{contrato.paidWithPayOff ? 'Pay-off' : contrato.situacao}}</v-btn></td>
                <td class="text-center">{{ definePaidDate(contrato) }}</td>
              </tr>
              <tr>
                <td class="text-center"><strong>Total Loans: </strong>{{contracts.length}}</td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"><strong>T.Payments: </strong>{{totals.totalPayments | toCurrency}}</td>
                <td class="text-center"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </v-container> 

    <v-dialog v-model="createReportProps.dialogOpened" transition="dialog-top-transition" max-width="600">
        <CreateReport 
          :filtros="finder" 
          :itens="contracts" 
          :totals="totals"
          :type="'C'"
          @close-dialog="createReportProps.dialogOpened = false"/>
     </v-dialog>

  </div>
</template>

<script>
import moment from 'moment';
import { ModelListSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import PartnerService from "../../partners/services/PartnerService";
import _ from 'lodash';
import LoanService from '../../loans/services/LoanService';
import CreateToast from '../../../utils/createToast';
import CreateReport from './components/CreateReport.vue';

export default {
  name: "Home",
  components: {
    ModelListSelect,
    DatePicker,
    CreateReport
  },
  data: () => ({
    contracts: [],
    partners: [{ nome: 'All' }],
    partner: {},
    finder: { 
        startDate: moment().format('YYYY-MM-DD'), 
        endDate: moment().format('YYYY-MM-DD'),
        situation: "All",
        partner: "All"
    },
    isLoading: false,
    options: [
      { id: 1, value: 'All' },
      { id: 2, value: 'ACTIVE' },
      { id: 3, value: 'PAID' },
      { id: 4, value: 'DEACTIVADED' }
    ],
    totals: {
      totalPayments: 0.0
    },
    comentario: "",
    componentKey: 0,
    isCheckAll: false,
    showFilters: false,
    createReportProps: {
      dialogOpened: false
    }
  }),
  created() {
    this.init()
  },

  methods: {
    async init() {
      this.isLoading = true;
      this.contracts = [];
      this.partners = [{ nome: 'All' }];
      this.componentKey + 1;
      this.populateFiltersAndResults();
      PartnerService.findAll().then(response => {
        response.data.map(result => {
          this.partners.push(result);
        });
        this.partners = this.partners.filter(partner => partner.situacao != 'DEACTIVADED').sort();
      }).catch(err => {
        CreateToast.createToastFailed('An error ocurred! Please try again!');
      }).finally(() => {
        this.isLoading = false;
      });
    },

    async executeSearch(finder) {
      this.isLoading = true;
      LoanService.buscaAvancada(
          finder.partner, 
          finder.situation, 
          finder.startDate, 
          finder.endDate
      ).then((response) => {
          this.contracts = response.data;
          this.totals.totalPayments = _.sumBy(this.contracts, 'totalPagar');
      }).finally(() => {
        this.saveResultsToLocalStorage();
        this.isLoading = false;
      }); 
    },

    loadPartner(idBanco) {
      this.$router.push(`/partners/${idBanco}`);
    },

    loadLoan(idLoan) {
      this.$router.push(`/loans/${idLoan}`);
    },

    print() {
      this.showFilters = true;
      setTimeout(() => {
        this.$htmlToPaper('printer-wrapper');
       
        this.showFilters = false;
      }, 500)
    },
    
    definePaidDate(contrato) {
        var dataPago = "";
        contrato.parcelas.forEach(parcela => {
            if (parcela.loanComplementar || parcela.nroParcela == contrato.qtdeParcelas) {
                if (parcela.situacao == 'PAID') {
                    dataPago = parcela.dataPagamentoPaga;
                }
            }
        });

        return dataPago;
    },

    defineColor(contrato) {
        if (contrato.situacao == 'ACTIVE') {
            return 'default';
        }

        if (contrato.situacao == 'DEACTIVADED') {
            return 'error'
        }

        if (contrato.situacao == 'PAID' && !contrato.paidWithPayOff) {
            return 'success'
        }

        return 'primary'
    },

    saveResultsToLocalStorage() {
      var filters = _.cloneDeep(this.finder);
      var results = _.cloneDeep(this.contracts);
      var totais = _.cloneDeep(this.totals);
      window.localStorage.setItem('beforeFiltersContracts', JSON.stringify(filters));
      window.localStorage.setItem('beforeResultsContracts', JSON.stringify(results));
      window.localStorage.setItem('beforeTotalsContracts', JSON.stringify(totais));
    },

    populateFiltersAndResults() {
      var filters = JSON.parse(window.localStorage.getItem('beforeFiltersContracts'));
      var results = JSON.parse(window.localStorage.getItem('beforeResultsContracts'));
      var totais = JSON.parse(window.localStorage.getItem('beforeTotalsContracts'));

      if (!_.isEmpty(filters)) {
        this.finder = filters;
      }

      if (!_.isEmpty(results)) {
        this.contracts = results;
      }

      if (!_.isEmpty(totais)) {
        this.totals = totais;
      }
    }
  }
};
</script>

<style scoped>
  a {
    text-decoration: none;
  }
</style>
<template>
  <v-app>
    <v-app-bar app dense flat outlined v-if="isLogged != null">
      <v-tabs >
        <v-tab to="/dashboard">Dashboard</v-tab>
        <v-tab to="/partners">Partners</v-tab>
        <v-tab to="/loans">Add Loans</v-tab>
        <v-tab to="/finder">Reports</v-tab>
        <v-tab to="/contracts">Contracts</v-tab>
        <v-tab to="/emitted-reports">Emitted reports</v-tab>
        <v-tab to="/emails">Configure Emails</v-tab>
        <div v-if="isLogged != null">
          <v-btn style="width: 100px; margin-top: 5px;" class="pull-right" depressed color="error" v-on:click="handleSignOut()">
            Sign out
          </v-btn>
        </div>
      </v-tabs>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth'
import CreateToast from './utils/createToast'

export default {
  data() {
    return {
      isLogged: null
    }
  },
  created() {
    setInterval(() => {
      this.isLogged = getAuth().currentUser;
    }, 250);

    window.addEventListener('beforeunload', () => {
      this.cleanLocalStorage();
    })
  },
  methods: {
    handleSignOut() {
      signOut(getAuth()).then((response) => {
        CreateToast.createToastSuccess(`Sign out with success!`)
      }).then(() => {
        this.$router.push({ path: '/' });
      }).catch(err => {
        CreateToast.createToastFailed(`An error occurred! Please try again later!`)
      })
    },

    cleanLocalStorage() {
      window.localStorage.setItem('beforeFiltersLoans', null);
      window.localStorage.setItem('beforeResultsLoans', null);
      window.localStorage.setItem('beforeTotalsLoans', null);
      window.localStorage.setItem('beforeFiltersContracts', null);
      window.localStorage.setItem('beforeResultsContracts', null);
      window.localStorage.setItem('beforeTotalsContracts', null);
    }
  }
}
</script>

<style>

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track-piece {
      background: #888
  }
  ::-webkit-scrollbar-thumb {
      background: rgb(63, 63, 63);
      border-radius: 4px;
  }

</style>
<template>
  <v-card>
      <v-toolbar style="display: flex; justify-content: center;" color="primary" dark>
        {{ isThisWeekPayments ? 'This week' : 'Next week' }} Payments
      </v-toolbar>
      <v-card-text style="text-align: center; margin-top: 15px; min-height: 300px;">
          <v-row justify="center" align="center" style="margin-top: 15px;">
            <v-container v-if="isLoading">
              <v-row justify="center" no-gutters class="my-12">
                <v-progress-circular indeterminate color="primary" size="64" />
              </v-row>

              <v-row justify="center">
                <span>Loading pending loans!</span>
              </v-row>
            </v-container>

            <h2 v-if="!isLoading && !loans.length">
              No loans pending for payment here.
            </h2>

            <v-simple-table
              fixed-header
              height="300"
              style="margin-bottom: 10px;"
              v-if="!isLoading && loans.length"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th scope="col" class="text-center" style="width: 130px;">Stock</th>
                    <th scope="col" class="text-center">Pay #</th>
                    <th scope="col" class="text-center">Due date</th>
                    <th scope="col" class="text-center">Payment</th>
                    <th scope="col" class="text-center">Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="loan in loans"
                    :key="loan.id"
                  >
                    <td class="text-center"><a v-on:click="loadLoan(loan.contrato.id)">{{ loan.contrato.stockId }}</a></td>
                    <td class="text-center">{{ loan.nroParcela }}/{{ loan.contrato.qtdeParcelas }}</td>
                    <td class="text-center">{{loan.dataPagamento}}</td>
                    <td class="text-center">{{loan.vlParcela | toCurrency}}</td>
                    <td class="text-center">{{loan.balanceAfterPayment | toCurrency}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
          <v-btn v-on:click="$emit('close-dialog', false)" text>
            Close
          </v-btn>
      </v-card-actions>
  </v-card>   
</template>

<script>
  import ParcelaService from '../modules/finder/services/ParcelaService';

  export default {
    name: "WeekPayments",
    props: {
      isThisWeekPayments: {
        type: Boolean,
        required: false
      },
      partnerId: {
        type: Number,
        required: false
      },
      dialogOpened: {
        type: Boolean
      }
    },

    data() {
      return {
        isLoading: false,
        loans: []
      }
    },

    watch: {
      dialogOpened: {
        handler: function (newVal, oldVal) {
          if (newVal) {
            this.init();
          }
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      init() {
        this.isLoading = true;
        if (this.isThisWeekPayments) {
          ParcelaService.thisweekpayments(this.partnerId).then(response => {
            this.loans = response.data;
          }).catch(err => {
            CreateToast.createToastFailed('An error ocurred! Please try again!');
          }).finally(() => {
            this.isLoading = false;
          });
        } else {
          ParcelaService.nextweekpayments(this.partnerId).then(response => {
            this.loans = response.data;
          }).catch(err => {
            CreateToast.createToastFailed('An error ocurred! Please try again!');
          }).finally(() => {
            this.isLoading = false;
          });
        }
      },

      loadLoan(id) {
        this.$router.push(`/loans/${id}`);
      }
    }
  }
</script>

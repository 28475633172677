<template>
<v-app style="backgroud-color: #F7F7F7;">
    <v-main style="display: flex; justify-content: center; align-items: center;">
        <div>
            <v-row align="center" justify="center" style="margin-top: 50px;" dense>
                <v-col cols="12" sm="8" md="4" lg="4">
                    <v-card style="margin-top: 30px; background: #F7F7F7;">
                        <v-row justify="space-around">
                            <img src="https://i.imgur.com/VZ8GK5N.jpg" style="margin-top: 10px;" alt="Logo">
                        </v-row>
                        <v-card-text>
                            <v-form>
                                <v-text-field label="Enter your email" v-model="email" name="email" prepend-inner-icon="mdi-email" type="email" class="rounded-0" outlined></v-text-field>
                                <v-text-field label="Enter your password" v-model="password" name="password" prepend-inner-icon="mdi-lock" type="password" class="rounded-0" outlined></v-text-field>
                                <v-btn class="rounded-0" color="#000000" x-large block dark v-on:click="handleLogin(email, password)">Login</v-btn>
                                
                                <v-card-actions class="text--secondary" style="margin-top: 15px;">
                                    <v-spacer></v-spacer>
                                    <a class="pl-2" style="color: #000000" v-on:click="recover()">Forgot your password?</a>
                                </v-card-actions>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-main>
</v-app>
</template>

<script>
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth'
import CreateToast from '../utils/createToast'
export default {
  name: 'Login',
  data () {
      return {
          email: "",
          password: ""
      }
  },
  methods: {
      handleLogin(email, password) {
          signInWithEmailAndPassword(getAuth(), email, password).then((response) => {
            CreateToast.createToastSuccess(`Logged with success!`)
            this.$router.push({ path: "/dashboard" });
          }).catch(err => {
              CreateToast.createToastFailed(err.message)
          })
      },

      recover() {
          this.$router.push({ path: '/recoverpwd' })
      }
  }
}
</script>

<style lang="css" scoped>



</style>
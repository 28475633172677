<template>
  <div>
    <v-container>

      <v-container v-if="isLoading">
        <v-row justify="center" no-gutters class="my-12">
          <v-progress-circular indeterminate color="primary" size="64" />
        </v-row>

        <v-row justify="center">
          <span>Calculating pay-off, please wait!</span>
        </v-row>
      </v-container>
		<v-container v-else>
			<v-row align="center" justify="center" style="margin-bottom: 50px;">
				<v-icon v-on:click="$router.back()" style="margin-right: 40px;">
						mdi-arrow-left
				</v-icon>
				<h2>
					{{partner.nome ? partner.nome : ''}} Pay-off Calculation as of {{self.today}}
				</h2>
			</v-row>

			<v-row align="center" justify="center">
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">
									Stock
								</th>
								<th class="text-left">
									Pay-off Amount
								</th>
								<th class="text-left">
									
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="loan in loans" :key="loan.id">
								<td><a v-on:click="loadLoan(loan.id)">{{ loan.stock }} {{loan.lastName}}</a></td>
								<td>{{ loan.valorTotalDesconto | toCurrency}}</td>
								<td>
									<v-dialog transition="dialog-top-transition" max-width="600">
										<template v-slot:activator="{ on, attrs }">
											<v-btn color="primary" v-bind="attrs" v-on="on">
												<span>Pay</span>
											</v-btn>
										</template>
										<template v-slot:default="dialog">
											<v-card>
												<v-toolbar color="primary" dark>Describe the email body</v-toolbar>
												<v-card-text style="text-align: center; margin-top: 15px;">
													<v-text-field
														label="Observation"
														v-model="comentario"
													></v-text-field>
												</v-card-text>
												<v-card-actions class="justify-end">
													<v-btn text @click="dialog.value = false">
														Close
													</v-btn>
													<v-btn v-on:click="payoff(loan, comentario)" depressed color="primary">
														Pay
													</v-btn>
												</v-card-actions>
											</v-card>
										</template>
									</v-dialog>
								</td>
							</tr>
							<tr>
								<td>
									<strong>Total:</strong> {{totalLoans}}
								</td>
								<td>
									<strong>Loan amount:</strong> {{totalLoansAmount | toCurrency}}
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-row>
		</v-container>
    </v-container>
  </div>
</template>

<script>

import PartnerService from '../modules/partners/services/PartnerService';
import ParcelaService from '../modules/finder/services/ParcelaService';
import CreateToast from '../utils/createToast';
import moment from 'moment';
import _ from 'lodash';
import LoanService from '../modules/loans/services/LoanService';

export default {
  name: "Home",
  data() {
    return {
		loans: [],
		partner: {},
		self: {
			today: moment().format('YYYY-MM-DD')
		},
		isLoading: false,
		comentario: "",
		totalLoans: 0,
		totalLoansAmount: 0.0
    }
  },

  async created() {
    this.init();
  },

  methods: {
    init() {
		if (this.$route.params.contractId) {
			this.isLoading = true;
			return LoanService.calculatePayoff(this.$route.params.contractId, this.$route.params.date).then(response => {
				this.loans = response.data;
				this.totalLoans = this.loans.length;
				this.totalLoansAmount = _.sumBy(this.loans, 'valorTotalDesconto')
				this.self.today = moment(this.loans[0].dataCalculoPayOff).format("YYYY-MM-DD")
			}).then(() => {
				this.isLoading = false;
			}).catch(err => {
				CreateToast.createToastFailed(err.response.data.message);
				this.$router.back()
			});
		}

		this.isLoading = true;
		return ParcelaService.calculatePayoff(this.$route.params.id).then(response => {
			this.loans = response.data;
			this.totalLoans = this.loans.length;
			this.totalLoansAmount = _.sumBy(this.loans, 'valorTotalDesconto')
		}).then(() => {
			PartnerService.findById(this.$route.params.id).then(response => {
				this.partner = response.data;
			}).catch(err => {
				CreateToast.createToastFailed('An error ocurred! Please try again!');
			});
		}).then(() => {
			this.isLoading = false;
		}).catch(err => {
			CreateToast.createToastFailed('An error ocurred! Please try again!');
		});
    },

    loadLoan(idLoan) {
      this.$router.push(`/loans/${idLoan}/report`);
    },

	payoff(loan, comentario) {
		this.$confirm(`Confirm loan repayment?`).then(response => {
			if (response) {
				this.isLoading = true;
				var fakeArrayParcelas = [];
				var parcelasPagar = _.filter(loan.parcelas, (parcela) => { return parcela.situacao == 'UNPAID' });
				parcelasPagar.forEach(parcela => {
					fakeArrayParcelas.push(parcela.nroParcela);
				})
				LoanService.pay(loan.id, _.sortBy(fakeArrayParcelas), this.self.today, true, comentario, loan.valorTotalDesconto).then(response => {
					CreateToast.createToastSuccess('Pay off made with success');
				}).then(() => {
					this.$router.push({ path: '/' });
				}).catch(() => {
					CreateToast.createToastFailed('An error ocurred! Please try again!');
				})
			}
		})
	}
  }
};
</script>

<template>
<v-app style="backgroud-color: #F7F7F7;">
    <v-main style="display: flex; justify-content: center; align-items: center;">
        <div>
            <v-row align="center" justify="center" style="margin-top: 50px;" dense>
                <v-col cols="12" sm="8" md="4" lg="4">
                    <v-card style="margin-top: 30px; background: #F7F7F7;">
                        <v-row justify="space-around">
                            <img src="https://i.imgur.com/VZ8GK5N.jpg" style="margin-top: 10px;" alt="Logo">
                        </v-row>
                        <v-card-text>
                            <v-form>
                                <v-text-field label="Enter your email" v-model="email" name="email" prepend-inner-icon="mdi-email" type="email" class="rounded-0" outlined></v-text-field>
                                <v-row>
                                    <v-col sm="6">
                                        <v-btn class="rounded-0" color="#000000" x-large block dark v-on:click="$router.back()">Cancel</v-btn>
                                    </v-col>
                                    <v-col sm="6">
                                        <v-btn class="rounded-0" color="#000000" x-large block dark v-on:click="handleRecover(email)">Recover password</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-main>
</v-app>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import CreateToast from '../utils/createToast'
export default {
  name: 'Login',
  data () {
      return {
          email: "",
          password: ""
      }
  },
  methods: {
      handleRecover(email) {
          sendPasswordResetEmail(getAuth(), email).then((response) => {
            CreateToast.createToastSuccess(`E-mail has been sended. Check your inbox! `)
            this.$router.push({ path: "/" });
          }).catch(err => {
              CreateToast.createToastFailed(err.message)
          })
      }
  }
}
</script>

<style lang="css" scoped>



</style>
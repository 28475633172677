<template>
  <v-card>
      <v-toolbar style="display: flex; justify-content: center;" color="primary" dark>
        Insert the check number
      </v-toolbar>
      <v-card-text style="text-align: center; margin-top: 15px;">
          <v-text-field
            label="Observation"
            v-model="comentario"
          ></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-end">
          <v-btn v-on:click="closeDialog()" text>
            Close
          </v-btn>
          <v-btn
            class="ma-2"
            :loading="saving"
            :disabled="saving"
            color="primary"
            v-on:click="emitir()"
          >
            Process
          </v-btn>
      </v-card-actions>
  </v-card>   
</template>

<script>
  import CreateToast from '../../../../utils/createToast';
  import ParcelaService from '../../services/ParcelaService';
  import ReportService from '../../services/ReportService';

  export default {
    name: "CreateReport",
    props: {
      itens: {
        type: Array,
        required: true
      },
      filtros: {
        type: Object,
        required: false
      },
      totals: {
        type: Object,
        required: false
      },
      type: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        comentario: null,
        saving: false
      }
    },

    methods: {
      emitir() {

        this.saving = true;

        const dto = ReportService.createDto(
          this.comentario, 
          this.itens, 
          this.filtros, 
          this.totals, 
          this.type
        );

        ParcelaService.createReport(dto).then(() => {
          setTimeout(() => {
            CreateToast.createToastSuccess('Report sended to processing, please wait!');
          }, 1000)
        }).catch(err => {
          CreateToast.createToastFailed('An error ocurred');
        }).finally(() => {
          setTimeout(() => {
            this.saving = false;
            this.closeDialog();
          }, 1000)
        })      
      },

      closeDialog() {
        this.comentario = null
        this.$emit('close-dialog');
      }
    }
  }
</script>

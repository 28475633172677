import { http } from '../../../config'

export default {

    createDto: (comentario, loans, filters, totals, type) => {
      const dto = {
        observation: comentario,
        itens: loans,
        filtros: {
          partnerName: filters.partner,
          status: filters.situation,
          initDate: filters.startDate,
          finalDate: filters.endDate,
        },
        totalPayments: totals.totalPayments,
        totalInterests: totals.totalInterests,
        totalPrincipalPayments: totals.totalPrincipalPayments,
        type: type
      }

      return dto;
    },

    findReports: (filtros) => {
      return http.post('/relatorios', filtros);
    }
}
<template>
  <div>
    <v-container>
      <v-container v-if="isLoading">
        <v-row justify="center" no-gutters class="my-12">
          <v-progress-circular indeterminate color="primary" size="64" />
        </v-row>

        <v-row justify="center">
          <span>Loading your Dashboard, please wait!</span>
        </v-row>

        <v-row justify="center" style="margin-top: 8px;">
          <span>This may gonna take a while.</span>
        </v-row>
      </v-container>

      <v-container v-if="!partners.length && !isLoading"  style="display: flex; align-items: center; height: 70vh;">
        <v-row justify="center">
          <v-alert dense type="info">
            Not found any partner active in system.
          </v-alert>
        </v-row>
      </v-container>

      <v-container v-if="partners.length && !isLoading">
        <v-row align="center" justify-sm="center" justify-md="end" justify-lg="end" style="padding-right: 25px;">
          <v-col sm="3" md="3" lg="2">
            <v-text-field 
              v-model="stock" 
              type="number" 
              label="type stock id" 
              hide-details="auto"
              outlined
              dense
              @keypress="enterClick($event, stock)">
            </v-text-field>
          </v-col>
          <v-col sm="1" md="1" lg="1">
            <v-btn height="39px" color="primary" v-on:click="searchByStock(stock)">Search</v-btn>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col sm="4" md="4" lg="6" style="height: 630px; min-width: 250px; overflow-y: auto; scrollbar-width: 1px;">
            <v-card width="600px" height="350px" style="margin-top: 13px;" v-for="partner in partners" :key="partner.id" outlined>
              <v-card-title style="justify-content: center;">
                <h3>{{ partner.nome }} Loans</h3>
              </v-card-title>
              <v-card-subtitle style="text-align: center;">
                <span>{{partner.totalReports}} Total</span> <br>
                <span>{{partner.totalReportsUnpaid}} Unpaid, {{partner.totalReportsPaid}} Paid, {{partner.totalReportsInactives}} Inactives</span> <br>
                <span>Balance {{partner.valorTotalReports ? partner.valorTotalReports : 0 | toCurrency}}</span>
              </v-card-subtitle>
              <v-container style="padding-left: 25px; padding-right: 25px;">
                <v-progress-linear
                  color="blue"
                  height="20"
                  v-bind:value="(partner.totalLoansPaid * 100)/partner.totalLoans"
                  style="border-radius: 4px;"
                ></v-progress-linear>
              </v-container>
              <v-row justify="center" style="margin-top: 20px;">
                <v-btn :disabled="!partner.totalReportsUnpaid" color="primary" width="200px" depressed v-on:click="calculatePayoff(partner.id)">
                  Calculate pay-off
                </v-btn>
              </v-row>

              <v-row justify="center" style="margin-top: 20px;">
                <v-btn v-on:click="openDialog(false, partner.id)" color="primary" width="200px" depressed>
                  Payments Next Week
                </v-btn>
              </v-row>

              <v-row justify="center" style="margin-top: 20px;">
                <v-btn v-on:click="openDialog(true, partner.id)" color="error" width="200px" depressed>
                  Payments This Week
                </v-btn>
              </v-row>
            </v-card>
          </v-col>

          <v-col sm="8" md="8" lg="6" style="margin-top: 15px;">
            <v-card outlined min-height="570">
              <v-row justify="center" style="margin-top: 15px;">
                <h2>
                  Loan Payments Pending
                </h2>
              </v-row>
              <v-row justify="center" style="margin-top: 15px;">
                <v-container v-if="isLoadingWeekPayments">
                  <v-row justify="center" no-gutters class="my-12">
                    <v-progress-circular indeterminate color="primary" size="64" />
                  </v-row>

                  <v-row justify="center">
                    <span>Loading pending loans!</span>
                  </v-row>
                </v-container>

                <v-simple-table
                  fixed-header
                  height="570"
                  style="margin-bottom: 10px;"
                  v-if="!isLoading && !isLoadingWeekPayments && partners.length"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th scope="col" class="text-center" style="width: 130px;">Stock</th>
                        <th scope="col" class="text-center">Pay #</th>
                        <th scope="col" class="text-center">Due date</th>
                        <th scope="col" class="text-center">Payment</th>
                        <th scope="col" class="text-center">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="loan in loans"
                        :key="loan.id"
                      >
                        <td class="text-center"><a v-on:click="loadLoan(loan.contrato.id)">{{ loan.contrato.stockId }}</a></td>
                        <td class="text-center">{{ loan.nroParcela }}/{{ loan.contrato.qtdeParcelas }}</td>
                        <td class="text-center">{{loan.dataPagamento}}</td>
                        <td class="text-center">{{loan.vlParcela | toCurrency}}</td>
                        <td class="text-center">{{loan.balanceAfterPayment | toCurrency}}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-dialog v-model="weekPaymentsProps.dialogOpened" transition="dialog-top-transition" max-width="600">
        <template >
            <WeekPayments 
              @close-dialog="closeDialog" 
              :dialogOpened="weekPaymentsProps.dialogOpened" 
              :isThisWeekPayments="weekPaymentsProps.isThisWeekPayments"
              :partnerId="weekPaymentsProps.partnerId"
            />
        </template>
    </v-dialog>
  </div>
</template>

<script>

import PartnerService from '../modules/partners/services/PartnerService';
import ParcelaService from '../modules/finder/services/ParcelaService';
import CreateToast from '../utils/createToast';
import _ from 'lodash';
import LoanService from '../modules/loans/services/LoanService';
import WeekPayments from './WeekPaymens.vue';

export default {
  name: "Home",
  data() {
    return {
      partners: [],
      loans: [],
      isLoading: false,
      stock: null,
      isLoadingWeekPayments: false,
      weekPaymentsProps: {
        dialogOpened: false,
        isThisWeekPayments: false,
        partnerId: 0
      }      
    }
  },

  components: {
    WeekPayments: WeekPayments
  },

  async created() {
    this.init();
  },

  methods: {
    init() {
      this.isLoading = true;
      PartnerService.findAllDashboard().then(response => {
        this.partners = _.remove(response.data, (item) => {
          return item.situacao == 'ACTIVE'
        });

        this.isLoadingWeekPayments = true;

        ParcelaService.thisweekpayments().then(responseLoan => {
          this.loans = _.remove(responseLoan.data, (item) => {
            return item.situacao == 'UNPAID'
          });
        }).then(() => {
          this.isLoadingWeekPayments = false;
        }).catch(err => {
          CreateToast.createToastFailed('An error ocurred! Please try again!');
        })
      }).catch(err => {
        CreateToast.createToastFailed('An error ocurred! Please try again!');
      }).finally(() => {
        this.isLoading = false;
      });
    },

    loadLoan(idLoan) {
      this.$router.push(`/loans/${idLoan}`);
    },

    loadPartnerThisWeekPayments(partner) {
      this.isLoading = true;
      ParcelaService.thisweekpayments(partner.id).then(responseLoan => {
        this.loans = _.remove(responseLoan.data, (item) => {
          return item.situacao == 'UNPAID'
        });
      }).catch(err => {
        CreateToast.createToastFailed('An error ocurred! Please try again!');
      }).finally(() => {
        this.isLoading = false;
      });
    },

    loadPartnerNextWeekPayments(partner) {
      this.isLoading = true;
      ParcelaService.nextweekpayments(partner.id).then(responseLoan => {
        this.loans = _.remove(responseLoan.data, (item) => {
          return item.situacao == 'UNPAID'
        });
      }).catch(err => {
        CreateToast.createToastFailed('An error ocurred! Please try again!');
      }).finally(() => {
        this.isLoading = false;
      });
    },

    calculatePayoff(partnerId) {
      this.$router.push(`/calculatePayoff/${partnerId}`);
    },

    searchByStock(stock) {
      LoanService.searchByStock(stock).then(response => {
        this.$router.push({ path: `/loans/${response.data.id}` });
      }).catch(err => {
        CreateToast.createToastFailed(err.response.data.message);
      })
    },

    enterClick(event, stock) {
      if (event.key === 'Enter') {
        this.searchByStock(stock)
      }
    },

    openDialog(isThisWeekPayments, partnerId) {
      this.weekPaymentsProps.dialogOpened = true;
      this.weekPaymentsProps.isThisWeekPayments = isThisWeekPayments;
      this.weekPaymentsProps.partnerId = partnerId;
      this.$emit('openedDialog');
    },

    closeDialog(value) {
      this.weekPaymentsProps.dialogOpened = value;
    }
  }
};
</script>

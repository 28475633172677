import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import CalculatePayoff from '../views/CalculatePayoff.vue';
import Login from '../views/Login.vue';
import LoginRecovery from '../views/LoginRecovery.vue';
import HomeContracts from '../modules/finder/views/HomeContracts.vue';
import EmittedReports from '../views/EmittedReports.vue';

import { getAuth } from 'firebase/auth'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },

  {
    path: "/recoverpwd",
    name: "Recoverypwd",
    component: LoginRecovery
  },

  {
    path: "*",
    redirect: "/"
  },

  {
    path: "/calculatePayoff/:id",
    name: "CalculatePayoff",
    component: CalculatePayoff,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/calculatePayoff/contract/:contractId/:date",
    name: "CalculatePayoff",
    component: CalculatePayoff,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/contracts",
    component: HomeContracts,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/emitted-reports",
    component: EmittedReports,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const currentUser = getAuth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next('/');
  else if (!requiresAuth && currentUser) next('/dashboard');
  else next();
});

export default router;
